import {
  AttributeMap,
  AvvElement,
  AvvElementType,
  type BlotName,
  blotNameMap,
  Delta,
  type DeltaOptions,
  DeltaParser,
  type Editor,
  extractLineFormats,
  HtmlParser,
  type IAvvElement,
  type MountedEditor,
  SelectionRange,
  type DeltaAttributes,
  type Attributes,
  type IAvvAny,
} from '@avvoka/editor'
import {BitArray, clone, equal, removeUndefined, Source, TextTools} from '@avvoka/shared'
import {useDocumentStore} from "@stores/generic/document.store";
import {useTemplateVersionStore} from "@stores/generic/templateVersion.store";
import {type DocxSettings, StoreMode, useDefaultDocxSettings} from "@stores/utils";
import {getActivePinia} from "pinia";
import {toRaw} from 'vue'
import type {StoreWithStyles} from "~/features/editor/styles/index";

export enum ReturnContent {
  /**
   * Returns delta with styles added/removed.
   */
  Delta = 'delta',

  /**
   * Returns HTML with styles added/removed.
   */
  Html = 'html',

  /**
   * Returns IAvvElement with styles added/removed.
   */
  AvvElement = 'avvElement'
}

type ReturnTypeMap<T extends ReturnContent> =
  T extends ReturnContent.Delta ? Delta :
    T extends ReturnContent.Html ? string :
      T extends ReturnContent.AvvElement ? IAvvElement :
        never;


//region Overrides
export function transformEditorStyles<Content extends string | Delta | IAvvElement>(
  content: Content,
  formats: Backend.Models.TemplateVersion.Styles,
  operation: 'add' | 'remove',
  returnType: ReturnContent.Html,
  mode: 'template' | 'document'
): string;
export function transformEditorStyles<Content extends string | Delta | IAvvElement>(
  content: Content,
  formats: Backend.Models.TemplateVersion.Styles,
  operation: 'add' | 'remove',
  returnType: ReturnContent.AvvElement,
  mode: 'template' | 'document'
): IAvvElement;
export function transformEditorStyles<Content extends string | Delta | IAvvElement>(
  content: Content,
  formats: Backend.Models.TemplateVersion.Styles,
  operation: 'add' | 'remove',
  returnType: ReturnContent.Delta,
  mode: 'template' | 'document'
): Delta;
//endregion

/**
 * Applies or removes editor styles to/from content based on the specified operation.
 * This function handles style application for paragraphs, TOC entries, and numbered elements while
 * maintaining proper attribute inheritance and formatting.
 *
 * @template Content - The input content type (string | Delta | IAvvElement)
 * @template TRet - The desired return content type (ReturnContent enum)
 *
 * @param {Content} content - The content to apply styles to. Can be:
 *   - HTML string
 *   - Delta object
 *   - IAvvElement object
 *
 * @param {Backend.Models.TemplateVersion.Styles} formats - Style definitions to apply/remove.
 * Each style contains formatting rules and attributes for different element types.
 * This is usually `store.docxSettings.formats`
 *
 * @param {'add' | 'remove'} operation - Determines whether to apply or remove the specified styles:
 *   - 'add': Applies the styles to the content
 *   - 'remove': Removes the styles from the content
 *
 * @param {ReturnContent} returnType - Specifies the desired output format:
 *   - ReturnContent.Delta: Returns a Delta object - Does run conversion
 *   - ReturnContent.Html: Returns an HTML string - Does run conversion
 *   - ReturnContent.AvvElement: Returns an IAvvElement object - Best performance, doesn't need any conversion
 *
 * @param {'template' | 'document'} mode - The content mode context:
 *   - 'template': Processing content in template context
 *   - 'document': Processing content in document context
 *
 * @returns {ReturnTypeMap<TRet>} The processed content in the specified return format
 *
 * @description
 * Key Features:
 * 1. Style Application
 *    - Processes styles for paragraphs ('P') and TOC entries ('AVV-TOC-ENTRY')
 *    - Handles numbering attributes and inheritance
 *    - Maintains proper container hierarchy
 *
 * 2. Numbering Handling
 *    - Manages numbered sections and their attributes
 *    - Preserves numbering continuity across sections
 *    - Handles section IDs and numbering patterns
 *
 * 3. Container Management
 *    - Isolates and unwraps containers as needed
 *    - Preserves special containers like 'AVV-TOC' and 'AVV-DUMMY'
 *
 * 4. Attribute Processing
 *    - Composes and excludes attributes based on operation
 *    - Preserves required attributes during removal
 *    - Handles text indentation and formatting
 *
 * Special Behaviors:
 * - When adding styles:
 *   - Generates unique numbering IDs
 *   - Converts paragraphs to TOC entries when needed
 *   - Inherits numbering attributes from previous sections
 *
 * - When removing styles:
 *   - Preserves required attributes (numbering, indentation)
 *   - Maintains container structure integrity
 *   - Handles attribute exclusion while preserving essential formatting
 *
 * @example
 * // Adding styles to HTML content and returning Delta
 * const result = transformEditorStyles(
 *   '<p data-avv-style="style1">Text</p>',
 *   styleFormats,
 *   'add',
 *   ReturnContent.Delta,
 *   'template'
 * );
 *
 * @example
 * // Removing styles from Delta and returning HTML
 * const result = transformEditorStyles(
 *   delta,
 *   styleFormats,
 *   'remove',
 *   ReturnContent.Html,
 *   'template'
 * );
 */
export function transformEditorStyles<Content extends string | Delta | IAvvElement, TRet extends ReturnContent>(
  content: Content,
  formats: Backend.Models.TemplateVersion.Styles,
  operation: 'add' | 'remove',
  returnType: TRet,
  mode: 'template' | 'document'
): ReturnTypeMap<TRet> {
  formats = toRaw(formats);

  const element = convertToAvvElement(content);
  let lastValidNumbering: Record<string, string> = {}
  const structuralChanges: Set<IAvvAny> = new Set()

  for (const line of element.extractChildrenGen<IAvvElement>(['P', 'AVV-TOC-ENTRY'])) {
    const styleIdentifier = line.attributes['data-avv-style']
    if(!styleIdentifier) continue;

    const style = formats[styleIdentifier]
    if(!style) continue;

    const path = line.path();
    const styleAttrs = getStyleAttributes(style)

    if(operation === 'add') {
      // Book Note 1: When numbering is not applied in html, but is listed in styles and line format has num-level=0, then numbering is disabled.
      if(line.attributes['num-level'] === '0' && !path.has("AVV-NUMBERED")) {
        delete styleAttrs['AVV-NUMBERED']
      }

      // Add unique data-numbered-id
      if(styleAttrs['AVV-NUMBERED']?.['data-section-id'] != null) {
        // TODO(check): Check if we need to override the numbered id when is already set? (not in style, but in document)
        // TODO(check): If the numbered id is in styles, this might cause troubles later?
        styleAttrs['AVV-NUMBERED']['data-numbered-id'] = TextTools.randomText(6)
      }

      // Convert to toc entry if needed
      if(line.nodeName === 'P' && styleAttrs['AVV-TOC-ENTRY']) {
        line.tagName = 'avv-toc-entry'
      }

      if(styleAttrs[line.nodeName] && (path.has('AVV-NUMBERED') || styleAttrs['AVV-NUMBERED'] != null)) {
        // Numbering is controlling text indent, therefore we need to remove text-indent
        delete styleAttrs[line.nodeName]['data-avv-text-indent']
        delete line.attributes['data-avv-text-indent']
      }

      // Isolate and unwrap all containers but 'dummy' and 'avv-toc'
      if (line.nodeName === 'AVV-TOC-ENTRY') {
        const isolatedContainers = line.isolatePathToRoot();

        for (const isolated of isolatedContainers) {
          if(isolated.previousSibling) {
            structuralChanges.add(isolated.previousSibling)
          }

          if(isolated.nextSibling) {
            structuralChanges.add(isolated.nextSibling)
          }
        }

        for(const isolated of isolatedContainers) {
          if(['AVV-TOC', 'AVV-DUMMY'].includes(isolated.nodeName)) continue;

          isolated.unwrap();
        }
      } else {

        //region TODO(perf): Optimize this!
        const order = path.map((node) => node.nodeName)
        const insertKeys = Object.keys(styleAttrs).filter(
          (key) => !order.includes(key)
        )

        for(const nodeName of insertKeys) {
          // TODO(fix): Possible issue with non string based attributes
          const attributes = styleAttrs[nodeName];

          // Inherit section id from previous numbering + other attributes
          if(nodeName === 'AVV-NUMBERED' && (attributes['data-numbered-id'] == null || attributes['data-section-id'] == null)) {
            const numbered: IAvvElement | undefined = path.get('AVV-NUMBERED')
            // TODO(refactor): Shouldn't we use AttributeMap.compose instead?
            attributes['data-numbered-id'] = numbered?.attributes?.['data-numbered-id'] ?? lastValidNumbering?.['data-numbered-id'];
            attributes['data-section-id'] = numbered?.attributes?.['data-section-id'] ?? lastValidNumbering?.['data-section-id'];
            attributes['data-mask-pattern'] = numbered?.attributes?.['data-mask-pattern'] ?? lastValidNumbering?.['data-mask-pattern'];
            attributes['level'] = numbered?.attributes?.['level'] ?? lastValidNumbering?.['level'];
            attributes['data-level'] = numbered?.attributes?.['data-level'] ?? lastValidNumbering?.['data-level'];
            setLastValidNumbering(attributes)
          }

          const createdContainer = AvvElement.create(AvvElementType.ELEMENT, nodeName.toLowerCase() as Lowercase<string>, undefined, removeUndefined(attributes));
          line.parent?.insertBefore(createdContainer, line);
          createdContainer.appendChild(line);
          structuralChanges.add(createdContainer)
          structuralChanges.add(createdContainer.parent)
        }

        const modifiedKeys = Object.keys(styleAttrs).filter(
          (key) => order.includes(key)
        );

        for(const nodeName of modifiedKeys) {
          const element = path.get<IAvvElement>(nodeName)
          if(!element) continue

          const attributes = element.attributes
          const newAttributes = AttributeMap.compose(
            styleAttrs[nodeName] as unknown as DeltaAttributes,
            attributes as unknown as DeltaAttributes,
            true,
            true
          ) as NonNullable<DeltaAttributes[string]>

          if(nodeName === 'AVV-NUMBERED') {
            const styles = (styleAttrs[nodeName]['data-styles'] ?? '').split(' ')
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            const newStyles = String(newAttributes['data-styles'] ?? '').split(' ')
            const resultStyles = new Set<string>([...styles, ...newStyles])
            if(resultStyles.size > 0) {
              newAttributes['data-styles'] = Array.from(resultStyles).join(' ')
            }
          }

          updateToObjectWithStrings(newAttributes);
          element.attributes = newAttributes;

          if(nodeName === 'AVV-NUMBERED') {
            setLastValidNumbering(newAttributes)
          }
        }

        //endregion
      }
    } else if (operation === 'remove') {

      //region TODO(perf): Optimize this!
      const order = path.map((node) => node.nodeName)
      const removeKeys = Object.keys(styleAttrs).filter(
        (key) => !order.includes(key)
      )

      if(removeKeys.length > 0) {
        const isolatedElements = line.isolatePathToRoot();

        for(const isolated of isolatedElements) {
          if(isolated.previousSibling) {
            structuralChanges.add(isolated.previousSibling)
          }

          if(isolated.nextSibling) {
            structuralChanges.add(isolated.nextSibling)
          }
        }

        for(const nodeName of removeKeys) {
          const element = isolatedElements.find(el => el.nodeName === nodeName)
          if(!element) continue

          element.unwrap();
        }
      }

      const modifiedKeys = Object.keys(styleAttrs).filter(
        (key) => order.includes(key)
      );

      for(const nodeName of modifiedKeys) {
        const element = path.get<IAvvElement>(nodeName)
        if(!element) continue

        const attributes = element.attributes
        const newAttributes = AttributeMap.exclude(
          styleAttrs[nodeName] as unknown as DeltaAttributes,
          attributes as unknown as DeltaAttributes,
          true,
          true
        ) as unknown as Record<string, string>

        if(nodeName === 'AVV-NUMBERED') {
          const styles = (attributes['data-styles'] ?? '').split(' ')
          const newStyles = (styleAttrs[nodeName]['data-styles'] ?? '').split(' ')
          // remove all styles from the style
          const resultStyles = styles.filter(style => !newStyles.includes(style))
          if(resultStyles.length > 0) {
            newAttributes['data-styles'] = resultStyles.join(' ')
          }
        }

        updateToObjectWithStrings(newAttributes);
        element.attributes = newAttributes;

        let requiredAttributes: string[] | null = null;

        if(element.nodeName === 'AVV-NUMBERED') {
          requiredAttributes = ['level', 'data-level', 'data-mask-pattern', 'data-mask-version', 'data-numbered-id', 'data-ov-indent', 'data-ov-level']
        } else if (element.nodeName === 'P' || element.nodeName === 'AVV-TOC-ENTRY') {
          requiredAttributes = ['data-avv-padding-left', 'data-avv-text-indent']
        }

        // Those attributes cannot be removed
        if(requiredAttributes) {
          for(const key of requiredAttributes) {
            if(newAttributes[key] == null && attributes[key] != null) {
              newAttributes[key] = attributes[key]
            }
          }
        }
      }
      //endregion
    }
  }

  // Resolve structural changes
  for(const change of structuralChanges) {
    if(!change.exists) continue;
    mergeAdjacentNodes(change)
  }

  return convertToDesiredType(element) as ReturnTypeMap<TRet> ;

  //region Helpers
  function convertToDesiredType(element: AvvElement){
    if(returnType === ReturnContent.AvvElement) return element as IAvvElement;
    if(returnType === ReturnContent.Html) return element.toHTML(false);
    if(returnType === ReturnContent.Delta) return element.toDelta();
    throw new Error('Invalid return type');
  }

  function convertToAvvElement(content: Content): AvvElement {
    if(typeof content === 'string') return HtmlParser.parse(content) as AvvElement;
    if(typeof content === 'object' && 'ops' in content) return DeltaParser.parse(content, mode) as AvvElement;
    if(typeof content === 'object' && 'nodeName' in content) return content as AvvElement;
    throw new Error('Invalid content type');
  }

  function mergeAdjacentNodes(change: IAvvAny) {
    // Helper function to check if two nodes can be merged
    function canMerge(node1: IAvvAny, node2: IAvvAny): boolean {
      if (node1.nodeName !== node2.nodeName) return false;

      if (node1.isElement() && node2.isElement()) {
        return equal(node1.attributes, node2.attributes);
      }

      return node1.isText() && node2.isText();
    }

    // Helper function to merge source into target
    function merge(source: IAvvAny, target: IAvvAny): void {
      if (source.isElement() && target.isElement()) {
        // Add the first child of source to structuralChanges because after moving all children
        // to target, this child will become adjacent to the last child of target. This adjacency
        // might create another opportunity for merging that we need to check in a subsequent pass.
        if(source.children[0]) {
          structuralChanges.add(source.children[0])
        }

        // Check if target has existing children - the last child of target will become
        // adjacent to the first child of source after merging, creating another potential
        // merge opportunity that needs to be checked
        if(target.children.length > 0 && source.children.length > 0) {
          structuralChanges.add(target.children[target.children.length - 1])
        }

        source.moveChildren(target);
      } else if (source.isText() && target.isText()) {
        target.data += source.data;
      }

      // Store references to siblings before removing the source node
      const prev = source.previousSibling, next = source.nextSibling;
      source.remove(false);

      // After source is removed, its previous and next siblings become adjacent to each other.
      // Add them to structuralChanges so they can be checked for potential merging in the next pass.
      if(prev) {
        structuralChanges.add(prev)
      }
      if(next) {
        structuralChanges.add(next)
      }
    }

    let curr = change;
    let prev = curr.previousSibling;
    while (prev && canMerge(prev, curr)) {
      merge(curr, prev);
      curr = prev;
      prev = prev.previousSibling;
    }

    let next = curr.nextSibling;
    while (next && canMerge(curr, next)) {
      merge(next, curr);
      next = curr.nextSibling;
    }
  }

  function updateToObjectWithStrings(obj: Record<string, unknown>): asserts obj is Record<string, string> {
    for(const key in obj) {
      const value = obj[key];
      obj[key] = String(value);
    }
  }

  function getStyleAttributes(
    style: Backend.Models.TemplateVersion.Style,
    memo: Record<string, Record<string, string>> = {}
  ): Record<string, Record<string, string>> {
    if (style == null) return {}
    if (style.parent && typeof style.parent === 'object') {
      // Temporarily disabled as styles already include their parent values
      // getStyleAttributes(style.parent, linesOnly, memo)
    }

    let attributes = style.definition as Record<string, Record<string, string>>;
    attributes = extractLineFormats(attributes as unknown as Attributes) as unknown as Record<string, Record<string, string>>;
    attributes = clone(attributes);
    for (const key in attributes) {
      // Skip keys that don't have blot representation
      blotNameMap.get(key as BlotName).ifPresent(blotDef => {
        memo[blotDef.nodeName] = attributes[key]
      })
    }
    return memo;
  }

  function setLastValidNumbering(
    attributes: Record<string, string>
  ) {
    lastValidNumbering = {
      'data-numbered-id': attributes['data-numbered-id'],
      'data-section-id': attributes['data-section-id'],
      'data-mask-pattern': attributes['data-mask-pattern'],
      'data-mask-version': attributes['data-mask-version'],
      'level': attributes['level'],
      'data-level': attributes['data-level']
    }
  }
  //endregion
}

export const hookStylesForEditor = (editor: Editor) => {
  setupEditorStyleGetter(editor);
  handeNegoLoadingContent(editor);
  overrideEditorFunctions(editor);
};

function setupEditorStyleGetter(editor: Editor) {
  editor.getStyleStore = () => getEditorStylesStore(editor);
  editor.getResolvedDocxStyles = (delta: Delta): Delta => {
    return transformEditorStyles(delta, getEditorStylesStore(editor).docxSettings.formats, 'add', ReturnContent.Delta, editor.options.mode);
  };
}

function handeNegoLoadingContent(editor: Editor) {
  if (editor.options.mode === 'document') {
    editor.negotiation!.onContentChange.subscribe(() => {
      // Timeout to allow systems to update the rendered content
      setTimeout(() => {
        const editorStyles = getEditorStylesStore(editor);
        const diff = editor.readonlyDelta.diff(
          transformEditorStyles(editor.getDelta(), editorStyles.docxSettings.formats, 'add', ReturnContent.Delta, editor.options.mode)
        )
        if (diff.length()) {
          console.log(
            "Styles are updating editor's delta based on negotiation content change.",
            diff
          )
          ;(editor as MountedEditor).update(
            diff,
            BitArray.fromSource(
              Source.USER,
              Source.DOCUMENT,
              Source.TRACKING_CHANGES,
              Source.STYLES
            )
          )
        }
      })
    })
  }
}

function overrideEditorFunctions(editor: Editor) {
  const _load = editor.load.bind(editor as MountedEditor);
  editor.load = function(this: MountedEditor, data: string | Delta | IAvvElement) {
    const stylesStore = getEditorStylesStore(this as Editor);

    const content: IAvvElement = transformEditorStyles(
      data,
      stylesStore.docxSettings.formats,
      'add',
      ReturnContent.AvvElement,
      editor.options.mode
    );

    return _load(content);
  };

  if(editor.negotiation) {
    const _updateDeltaFromDocument = editor.negotiation?.updateDeltaFromDocument.bind(editor.negotiation);
    editor.negotiation.updateDeltaFromDocument = function (delta: Delta | undefined, sources: BitArray): Delta {
      if(delta != null) {
        throw new Error("updateDeltaFromDocument called on negotiation update with specified delta. This is not supported with styles yet.")
      }

      const stylesStore = getEditorStylesStore(editor);
      const styledDelta = editor.readonlyDelta;
      const documentDelta = this.documentDelta.clone();

      const content: Delta = transformEditorStyles(
        documentDelta,
        stylesStore.docxSettings.formats,
        'add',
        ReturnContent.Delta,
        editor.options.mode
      )

      const diff = styledDelta.diff(content)
      _updateDeltaFromDocument(diff, sources);
      return diff;
    }
  }

  const _getDelta = editor.getDelta.bind(editor);
  editor.getDelta = function(this: MountedEditor, range?: SelectionRange, options?: DeltaOptions) {
    const stylesStore = getEditorStylesStore(this as Editor);
    return transformEditorStyles(
      _getDelta(range, options),
      stylesStore.docxSettings.formats,
      'remove',
      ReturnContent.Delta,
      editor.options.mode
    );
  };
}

export function getEditorStylesStore(editor: Editor | MountedEditor) {
  const store = editor.options.styleStore
    ? editor.options.styleStore
    : editor.options.mode === 'document'
      ? useDocumentStore(getActivePinia())
      : useTemplateVersionStore(getActivePinia())

  if (store.hydrated || store.storeMode == StoreMode.NewData) {
    return store
  } else {
    const raw = useDefaultDocxSettings()
    const docxSettings = {
      formats: raw.formats,
      docxNamesByOrigin: raw.docx_names_by_origin,
      stylesRelations: raw.stylesRelations,
      inactiveFormats: raw.inactiveFormats,
      metadata: raw.metadata,
      version: raw.version,
      dataDocxRef: raw['data-docx-ref']
    }

    return {
      docxSettings,
      styles: docxSettings,
      defaultStyle: {
        key: ''
      }
    }
  }
}

declare module '@avvoka/editor' {
  interface Editor {

    /**
     * @deprecated use `transformEditorStyles(delta, editor.getStyleStore().docxSettings.formats, 'add', editor.options.mode);` instead
     */
    getResolvedDocxStyles(delta: Delta): Delta

    /**
     * @deprecated use `getEditorStylesStore(editor)` instead
     */
    getStyleStore():
      | StoreWithStyles
      | {
      styles: DocxSettings
      docxSettings: DocxSettings
      defaultStyle: Backend.Models.TemplateVersion.Style & {
        key: string
      }
    }
  }
}

